import cloneDeep from "lodash.clonedeep";

/**
 * Helper function to decapsulate recursion from cloning.
 * @see replaceValueInObject() for more
 *
 * */
const replaceValues = (
  clone: Record<string, unknown>,
  placeholder: string,
  replaceValue: unknown,
) => {
  const replaceInClone = clone;

  Object.entries(replaceInClone).forEach(([key, value]) => {
    if (typeof value === "object") {
      replaceValues(
        value as Record<string, unknown>,
        placeholder,
        replaceValue,
      );
    }

    const shouldUpdateWholeValue =
      typeof value === "string" && value === placeholder;

    const shouldUpdatePartOfString =
      typeof value === "string" &&
      value.includes(placeholder) &&
      typeof replaceValue === "string";

    // We check for typeof value === "string" because placeholders are always strings or part of a string
    if (shouldUpdateWholeValue) {
      replaceInClone[key] = replaceValue;

      return;
    }

    // This is the case, where we want to complement a given string like URL test/:personId/hello -> replace not everything
    if (shouldUpdatePartOfString) {
      replaceInClone[key] = (replaceInClone[key] as string).replace(
        placeholder,
        replaceValue,
      );
    }
  });
};

/**
 * This function can dynamically replace data/values with given placeholders in an object.
 * This is especially useful, if you have to make custom api calls with IDs you retrieve only in runtime and
 * therefor can't predefine them. Important is, that you throw in a deep cloned object, to ensure immutability of the
 * original object.
 *
 * @param {object} replaceIn - The object you want to modify. E.g. a configuration object
 * @param {string} toReplace - The placeholder the function has to look for. E.g. ':personId'
 * @param {unknown} replaceWith - The value you want to use as a replacement for the placeholder.
 *
 *
 * example input:
 *
 * replaceIn = [{ replace: ":placeholder" }, { notToReplace: "I don't want to get replaced" }];
 * placeholder = ":placeholder"
 * replaceValue = "Ha! I replaced you"
 *
 * example output:
 *
 * [{ replace: "Ha! I replaced you" }, { notToReplace: "I don't want to get replaced" }]
 * */
export const replaceValueInObject = (
  replaceIn: Record<string, unknown>,
  toReplace: string,
  replaceWith: unknown,
) => {
  // Because of recursion in this function, it won't work, if we clone the object for every recursion. If we do so,
  // it gets cloned on every loop -> Changes/Value reassignments are not bubbled up. That is why we need to
  // use replaceValues() function above, to decapsulate the recursion from cloning.
  const clonedObject = cloneDeep(replaceIn);

  replaceValues(clonedObject, toReplace, replaceWith);

  return clonedObject;
};
