import { getDataByPath } from "@paul/paul-components-collector-package";
import { CONDITION_OPERATION, IFilterArrayByValue } from "../../interfaces";

// todo: Maybe extend and clean up in the future for more complex filtering. For now only simple filters possible.
export const filterArray = (
  toTransform: Record<string, unknown>[],
  transformationWay: IFilterArrayByValue,
) => {
  switch (transformationWay.operation) {
    case CONDITION_OPERATION.EQUAL:
      return toTransform.filter(
        (item) =>
          getDataByPath(transformationWay.pathToValue, item, true) ===
          transformationWay.compareWithValue,
      );

    case CONDITION_OPERATION.NOT_EQUAL:
    default:
      return toTransform.filter(
        (item) =>
          getDataByPath(transformationWay.pathToValue, item, true) !==
          transformationWay.compareWithValue,
      );
  }
};
